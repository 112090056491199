import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../services/UserContext";
import {
  basicAccess,
  supervisor,
  admin,
  testing,
  level1,
} from "../config/levelGroups"; //
import NuovoPayStatus from "./NuovoPayStatus";

function AdminPanel() {
  const { user } = useUser();

  return (
    <div>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Admin Panel</h2>
        <NuovoPayStatus /> {/* Aquí mostramos el estado de NuovoPay */}
      </header>{" "}
      <nav>
        <ul>
          {/* ------------------------------------------------------------------------ */}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/BlackList">Lista Negra</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/UserInformation">Informacion de Usuario</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/enrollmentVerify">
                  Verificar correcto enrolamiento Nuovopay
                </Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user &&
            (admin.includes(user.email) ||
              user.email === "plata@finnix.ai") && (
              <>
                <li>
                  <Link to="/UnrollmentManualy">
                    Eliminar Nuovopay (Creditos Pagos)
                  </Link>
                </li>
                <br />
              </>
            )}
          {/* ------------------------------------------------------------------------ */}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/SendEmailApproved">
                  Enviar Correo de Aprobacion (Solo Enrolamiento: True)
                </Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/generateClabe">Generar Clabe STP</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user &&
            (admin.includes(user.email) ||
              user.email === "plata@finnix.ai") && (
              <>
                <li>
                  <Link to="/NuovoPay">Desbloqueo NuovoPay</Link>
                </li>
                <br />
              </>
            )}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/OxxoReference">Obtener Referencia OXXO</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {/* {user && admin.includes(user.email) && (
            <>
              <li>
                <Link to="/RenovationExceptional">Creditos Excepcionales</Link>
              </li>
              <br />
            </>
          )} */}
          {/* ------------------------------------------------------------------------ */}
          {/* {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/nextCreditProjection">
                  Proyeccion proximo credito
                </Link>
              </li>
              <br />
            </>
          )} */}
          {/* ------------------------------------------------------------------------ */}
          {/* {user && supervisor.includes(user.email) && (
            <>
              <li>
                <Link to="/reports">Reportes</Link>
              </li>
              <br />
            </>
          )} */}
          {/* ------------------------------------------------------------------------ */}
          {user && basicAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/validate/clabe">Validación de Clabes</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
        </ul>
      </nav>
    </div>
  );
}

export default AdminPanel;
