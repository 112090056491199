import React, { useState, useEffect } from "react";
import axios from "axios";
import env from "../config/enviroment";
import Swal from "sweetalert2"; // Importar SweetAlert2
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CSVLink } from "react-csv";

const Users = () => {
  const [customerId, setCustomerId] = useState("");
  const [selectedList, setSelectedList] = useState("estafas"); // Cambiar "customers" a "estafas"
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        selectedList === "estafas" // Cambiar "customers" a "estafas"
          ? `${env.API_URL}/blacklist/`
          : `${env.API_URL}/blacklist/support`, // Cambiar "support" a "soporte"
        {
          headers: { "api-key": env.API_KEY_FABISAN },
        }
      );
      const sortedUsers = response.data.sort((a, b) => {
        return a.customerId - b.customerId; // Ordena de manera ascendente
      });
      setUsers(sortedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async () => {
    if (!customerId) return;
    setLoading(true);
    try {
      await axios.post(
        selectedList === "estafas" // Cambiar "customers" a "estafas"
          ? `${env.API_URL}/blacklist/${customerId}`
          : `${env.API_URL}/blacklist/support/${customerId}`, // Cambiar "support" a "soporte"
        {},
        {
          headers: { "api-key": env.API_KEY_FABISAN },
        }
      );
      setCustomerId("");
      fetchUsers();
      Swal.fire("Éxito", "Usuario agregado con éxito", "success"); // Alerta de éxito
    } catch (error) {
      console.error("Error adding user:", error);
      Swal.fire("Error", "No se pudo agregar el usuario", "error"); // Alerta de error
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = async () => {
    if (!customerId) return;
    setLoading(true);
    try {
      await axios.delete(
        selectedList === "estafas" // Cambiar "customers" a "estafas"
          ? `${env.API_URL}/blacklist/customer/${customerId}`
          : `${env.API_URL}/blacklist/support/customer/${customerId}`, // Cambiar "support" a "soporte"
        {
          headers: { "api-key": env.API_KEY_FABISAN },
        }
      );
      setCustomerId("");
      fetchUsers();
      Swal.fire("Éxito", "Usuario eliminado con éxito", "success"); // Alerta de éxito
    } catch (error) {
      console.error("Error removing user:", error);
      Swal.fire("Error", "No se pudo eliminar el usuario", "error"); // Alerta de error
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchUsers();
  }, [selectedList]);

  const filteredUsers = users.filter((user) => {
    const customerId = user.customerId
      ? String(user.customerId).toLowerCase()
      : "";
    const name = user.name ? user.name.toLowerCase() : "";
    const phone = user.phone ? user.phone.toLowerCase() : "";

    return (
      customerId.includes(search.toLowerCase()) ||
      name.includes(search.toLowerCase()) ||
      phone.includes(search.toLowerCase())
    );
  });

  const exportHeaders = [
    { label: "Customer ID", key: "customerId" },
    { label: "Phone", key: "PHONE" },
    { label: "CURP", key: "CURP" },
  ];

  return (
    <Box sx={{ p: 1 }}>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{ fontSize: "1.5rem", mb: 4 }} // Agregar margen inferior (mb) para separar el título
      >
        Panel de Listas Negras:
      </Typography>

      {/* Actions */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: "8px" }}>
            <CardContent>
              <Typography variant="body1" sx={{ fontSize: "1rem", mb: 1 }}>
                Bloquear/Desbloquear Usuario:
              </Typography>
              <TextField
                label="Customer ID"
                value={customerId}
                onChange={handleInputChange(setCustomerId)}
                fullWidth
                sx={{ mb: 1 }}
                size="small"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUser}
                disabled={loading}
                fullWidth
                sx={{ mb: 1 }}
                size="small"
              >
                {loading ? <CircularProgress size={24} /> : "Bloquear Usuario"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleRemoveUser}
                disabled={loading}
                fullWidth
                size="small"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Desbloquear Usuario"
                )}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ padding: "8px" }}>
            <CardContent>
              <Typography variant="body1" sx={{ fontSize: "1rem", mb: 1 }}>
                Seleccionar lista:
              </Typography>
              <Button
                variant={selectedList === "estafas" ? "contained" : "outlined"} // Cambiar "customers" a "estafas"
                onClick={() => setSelectedList("estafas")}
                sx={{ mr: 1 }}
                size="small"
              >
                Estafas
              </Button>
              <Button
                variant={selectedList === "soporte" ? "contained" : "outlined"} // Cambiar "support" a "soporte"
                onClick={() => setSelectedList("soporte")}
                size="small"
              >
                Soporte
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Table */}
      <Paper sx={{ mt: 2 }}>
        <TextField
          label="Busqueda"
          value={search}
          onChange={handleSearch}
          fullWidth={false}
          sx={{ mb: 1 }}
          size="small"
        />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>CURP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.customerId}>
                      <TableCell>{user.customerId}</TableCell>
                      <TableCell>{user.PHONE}</TableCell>
                      <TableCell>{user.CURP}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Button variant="outlined" size="small">
          <CSVLink
            data={filteredUsers}
            headers={exportHeaders}
            filename="users.csv"
          >
            Exportar a CSV
          </CSVLink>
        </Button>
      </Box>
    </Box>
  );
};

export default Users;
