import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { TextField, Button, Typography, Box } from "@mui/material";

function OxxoReference() {
  const [clientId, setClientId] = useState("");

  const handleClientIdChange = (event) => {
    const inputNumber = event.target.value.replace(/\D/, "").slice(0, 25);
    setClientId(inputNumber);
  };

  const handleGetReference = () => {
    axios
      .get(
        `${env.API_URL}/intelligential/client/conektaReference/${clientId}`,
        {
          headers: {
            "api-key": env.API_KEY_FABISAN,
          },
        }
      )
      .then((response) => {
        swal("Referencia obtenida", response.data, "success");
        setClientId("");
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          "Error inesperado al obtener la referencia";
        swal("Error", errorMessage, "error");
        setClientId("");
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleGetReference();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>
        Consultar Referencia OXXO
      </Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">Ingrese el Client ID:</Typography>
        <TextField
          variant="outlined"
          value={clientId}
          onChange={handleClientIdChange}
          onKeyPress={handleKeyPress}
          fullWidth
          placeholder="Id intelligential" // Aquí se agrega el texto dentro del campo
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleGetReference}
        >
          Obtener
        </Button>
      </Box>
    </Box>
  );
}

export default OxxoReference;
